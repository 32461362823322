import {createSvgIcon} from '../create-svg-icon';

export const CreatePPIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="20 0 400 500">
  <path fill="#ffba00" d="m424.39,75v425H99.39c-41.42,0-75-33.58-75-75V0h325c41.42,0,75,33.58,75,75Z"/>
  <rect fill="#fff" x="99.39" y="160" width="250" height="40"/>
  <rect fill="#fff" x="99.39" y="299.5" width="250" height="40"/>
  <rect fill="#fff" x="30.39" y="232.16" width="173" height="35" transform="translate(366.55 132.77) rotate(90)"/>
  <rect fill="#fff" x="245.39" y="232.16" width="173" height="35" transform="translate(581.55 -82.23) rotate(90)"/>
</svg>
, 'CreatePPOutlined');
