import {createSvgIcon} from '../create-svg-icon';

export const CreateDocumentIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 400 500">
  <path fill="#246fef" d="m400,75v425H75c-41.42,0-75-33.58-75-75V0h325c41.42,0,75,33.58,75,75Z"/>
  <rect fill="#fff" x="75" y="162.5" width="250" height="35" />
  <rect fill="#fff" x="75" y="233" width="250" height="35" />
  <rect fill="#fff" x="75" y="302" width="200" height="35" />
</svg>
, 'CreateDocumentOutlined');
