import {createSvgIcon} from '../../../icons/create-svg-icon';

export const ArchiveFileIcon = createSvgIcon(
<svg id="Archive" xmlns="http://www.w3.org/2000/svg" viewBox="405 50 505 500">
  <path className="cls-3" d="m908,206.24v204.97c0,8.42-1.39,16.52-3.94,24.08-.59,1.74-1.24,3.45-1.96,5.13-.71,1.68-1.49,3.34-2.31,4.96-1.06,2.08-2.22,4.09-3.46,6.05-.52.81-1.05,1.62-1.6,2.41-1.03,1.48-2.11,2.93-3.24,4.34-.38.47-.76.93-1.15,1.39-.31.36-.62.73-.94,1.09t0,.01c-1.14,1.31-2.33,2.57-3.56,3.78-.52.51-1.04,1.02-1.57,1.52-1.07,1-2.16,1.97-3.29,2.9-7.29,6.09-15.75,10.8-25,13.77-.42.14-.85.27-1.27.4-1.51.45-3.05.86-4.6,1.23-1.55.36-3.11.68-4.7.94-1.58.26-3.18.48-4.8.64-2.52.26-5.08.39-7.67.39h-349.94c-41.42,0-75-33.58-75-75v-235.5c0-38.48,28.95-70.17,66.25-74.5h115.3l33.43,14.26,36.66,15.64-.47.2h177.69c39.63,2.01,71.14,34.77,71.14,74.9Z"/>
  <path className="cls-2" d="m908.32,224.66v187.47c0,8.42-1.39,16.52-3.94,24.08-.6,1.74-1.25,3.45-1.96,5.13-.71,1.68-1.49,3.34-2.31,4.96-1.06,2.08-2.22,4.09-3.46,6.05-.52.81-1.05,1.62-1.6,2.41-1.38,1.98-2.84,3.89-4.39,5.73-.31.36-.62.73-.94,1.09t0,.01c-1.14,1.3-2.33,2.56-3.56,3.78-8.29,8.22-18.48,14.52-29.86,18.19-.42.14-.85.27-1.27.4-1.51.45-3.05.86-4.6,1.23-1.55.36-3.11.67-4.7.94-1.58.26-3.18.48-4.8.64-2.52.26-5.08.39-7.67.39h-349.94c-41.42,0-75-33.58-75-75v-187.5c0-19.24,14.48-35.09,33.12-37.25.81-.1,1.62-.17,2.45-.21.64-.03,1.28-.04,1.93-.04h425c.65,0,1.29.01,1.93.04.83.04,1.64.11,2.45.21,18.65,2.16,33.12,18.01,33.12,37.25Z"/>
  <path className="cls-1" d="m479,314.74h-15c-5.52,0-10,4.48-10,10v45c0,5.52,4.48,10,10,10h15c5.52,0,10-4.48,10-10v-45c0-5.52-4.48-10-10-10Zm5,55c0,2.76-2.24,5-5,5h-15c-2.76,0-5-2.24-5-5v-25c0-2.76,2.24-5,5-5h15c2.76,0,5,2.24,5,5v25Z"/>
  <rect className="cls-1" x="454" y="321.74" width="400" height="10"/>
  <rect className="cls-1" x="493" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="503" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="513" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="523" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="533" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="543" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="483" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="553" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="563" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="573" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="583" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="593" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="603" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="613" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="623" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="633" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="643" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="653" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="663" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="673" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="683" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="693" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="703" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="713" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="723" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="733" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="743" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="753" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="763" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="773" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="783" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="793" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="803" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="813" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="823" y="331.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="833" y="316.74" width="10" height="5" rx="1.5" ry="1.5"/>
  <rect className="cls-1" x="853" y="316.74" width="10" height="20" rx="2" ry="2"/>
  <rect className="cls-1" x="843" y="331.74" width="13" height="5" rx="1.5" ry="1.5"/>
</svg>
);
