// @ts-nocheck
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {useUser} from '../use-user';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {SocialLoginPanel} from './social-login-panel';
import {BasicInfoPanel} from './basic-info-panel/basic-info-panel';
import {ChangePasswordPanel} from './change-password-panel/change-password-panel';
import {LocalizationPanel} from './localization-panel';
import {AccessTokenPanel} from './access-token-panel/access-token-panel';
import {DangerZonePanel} from './danger-zone-panel/danger-zone-panel';
import {Trans} from '@common/i18n/trans';
import {StaticPageTitle} from '@common/seo/static-page-title';
import {AccountSettingsPanel} from '@common/auth/ui/account-settings/account-settings-panel';
import {TwoFactorStepper} from '@common/auth/ui/two-factor/stepper/two-factor-auth-stepper';
import {NotificationSettingsPage} from '@common/notifications/subscriptions/notification-settings-page';
import {
  AccountSettingsId,
  AccountSettingsSidenav,
} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {SessionsPanel} from '@common/auth/ui/account-settings/sessions-panel/sessions-panel';
import {RootFolderPage} from '../../../../../../resources/client/drive/drive-page/drive-page';
import {SidebarMenu} from '../../../../../../resources/client/drive/layout/sidebar/sidebar-menu';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {Button} from '@common/ui/buttons/button';
import {Link,Route} from 'react-router-dom';
import {useAuth} from '@common/auth/use-auth';
import clsx from 'clsx';
import {useSettings} from '@common/core/settings/use-settings';
import { SidebarMenuProps } from '@common/ui/navigation/navbar/sidebar-menu-props';
import React,{ReactElement,useState,ReactNode,useEffect, useLayoutEffect,useRef} from 'react';
import {ListboxItemProps} from '@common/ui/forms/listbox/item';
import {Badge} from '@common/ui/badge/badge';
import {IconButton} from '@common/ui/buttons/icon-button';
import {PersonIcon} from '@common/icons/material/Person';
import {ButtonBase} from '@common/ui/buttons/button-base';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';
import {useIsMobileMediaQuery} from '@common/utils/hooks/is-mobile-media-query';
import { changeThemeSidebar } from '@common/ui/navigation/navbar/color-theme-change';
import {useLogout} from '@common/auth/requests/logout';
import {DarkModeIcon} from '@common/icons/material/DarkMode';
import {LightModeIcon} from '@common/icons/material/LightMode';
import {ExitToAppIcon} from '@common/icons/material/ExitToApp';
import {ChatOneIcon} from '@common/icons/material/ChatOne';
import {SettingsIcon} from '@common/icons/material/Settings';
import {DriveRootIcon} from '@common/icons/material/DriveRoot';
import {NotificationDialogTrigger} from '@common/notifications/dialog/notification-dialog-trigger';
import {StorageMeter} from '../../../../../../resources/client/drive/layout/sidebar/storage-summary/storage-meter';
import {List, ListItem} from '@common/ui/list/list';
import {LoginIcon} from '@common/icons/material/Login';
import {LockIcon} from '@common/icons/material/Lock';
import {PhonelinkLockIcon} from '@common/icons/material/PhonelinkLock';
import {LanguageIcon} from '@common/icons/material/Language';
import {ApiIcon} from '@common/icons/material/Api';
import {DangerousIcon} from '@common/icons/material/Dangerous';
import {DevicesIcon} from '@common/icons/material/Devices';
import {AttachMoneyIcon} from '@common/icons/material/AttachMoney';
import {AccountSidebar} from './account-sidebar';
import {DashboardSidenav} from '@common/ui/layout/dashboard-sidenav';
import {DashboardLayout} from '@common/ui/layout/dashboard-layout';
import { useLocation } from 'react-router-dom';
import {StorageUsed} from './storageUsed';
/*import {Tabs} from '@common/ui/tabs/tabs';
import {TabList} from '@common/ui/tabs/tab-list';
import {Tab} from '@common/ui/tabs/tab';
import {TabPanel, TabPanels} from '@common/ui/tabs/tab-panels';*/

interface Tab {
  label: string;
  anchor: string;
}

const TabList = ({
  tabs,
  activeTab,
  onTabChange,
}) => {
	const refs = useRef(tabs.map(() => React.createRef()));
  const [buttonWidths, setButtonWidths] = useState([]);
  const slidingBorderRef = useRef(null);
  useEffect(() => {
    const widths = refs.current.map(ref => ref.current.offsetWidth);
    setButtonWidths(widths);
  }, [tabs]);
  useEffect(() => {
    const updateWidths = () => {
      const widths = refs.current.map(ref => ref.current.offsetWidth);
      setButtonWidths(widths);
    };
    window.addEventListener('resize', updateWidths);
    return () => {
      window.removeEventListener('resize', updateWidths);
    };
  }, [refs]);
  return (
    <div className="tab-list flex relative max-w-full overflow-auto" role="tablist">
      {tabs.map((tab, index) => (
        <button
          key={index}
		  type="button"
		  role="tab"
		  ref={refs.current[index]}
          className={`${activeTab === tab.anchor ? 'text-primary active' : 'text-muted hover:text-main'} tab-bar tracking-wide overflow-hidden capitalize text-sm flex items-center justify-center outline-none transition-colors focus-visible:ring focus-visible:ring-2 ring-inset whitespace-nowrap cursor-pointer min-w-min px-18 h-48`}
          onClick={() => onTabChange(tab.anchor)}
        >
          <Trans message={tab.label} />
        </button>
      ))}
	<div
		className="absolute bottom-0 left-0 h-2 bg-primary transition-all"
		ref={slidingBorderRef}
		style={{
          width: `${buttonWidths[tabs.findIndex(tab => tab.anchor === activeTab)] || 0}px`,
          transform: `translateX(${buttonWidths.slice(0, tabs.findIndex(tab => tab.anchor === activeTab)).reduce((acc, width) => acc + width, 0)}px)`,
        }}
	/>
    </div>
  );
};

export interface NavbarAuthUserProps {
  items?: ReactElement<ListboxItemProps>[];
}
export function AccountSettingsPage({items}: NavbarAuthUserProps) {
  const {data, isLoading} = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });
  const {billing} = useSettings();
  const isMobile = useIsMobileMediaQuery();
  const navigate = useNavigate();
  const {user, isLoggedIn,isSubscribed,hasPermission} = useAuth();
  const {notifications, themes,api,social} = useSettings();
  const logout = useLogout();
  const {selectedTheme, selectTheme} = useThemeSelector();
  if (!selectedTheme || !user) return null;
  const hasUnreadNotif = !!user.unread_notifications_count;
  const showNotifButton = !isMobile && isLoggedIn && notifications?.integrated;
  const p = AccountSettingsId;	
  const [activeTab, setActiveTab] = useState('account');
  useEffect(() => {
	  if (window.location.hash.substring(1)) {
     	 setActiveTab(window.location.hash.substring(1));
  	}
  });
	
  const handleTabChange = (tabName) => {
	  window.location.hash = tabName;
    setActiveTab(tabName);
  };
	
  const tabs: Tab[] = [
    { label: 'Account details', anchor: 'account' },
    { label: 'Billing', anchor: 'billing' },
    { label: 'Security', anchor: 'security' },
    { label: 'Notifications', anchor: 'notifications' },
    { label: 'Developers', anchor: 'developers' },
  ];	

  const socialEnabled =
    social?.envato || social?.google || social?.facebook || social?.twitter;
  const mobileButton = (
    <Badge
      badgeLabel={user?.unread_notifications_count}
      badgeIsVisible={hasUnreadNotif}
    >
      <IconButton size="md">
        <PersonIcon />
      </IconButton>
    </Badge>
  );
  const desktopButton = (
    <ButtonBase className="flex items-center">
      <img
        className="w-32 h-32 object-cover flex-shrink-0 rounded mr-12"
        src={user.avatar}
        alt=""
        style={{borderRadius: "50%"}}
      />
    </ButtonBase>
  );

  return (
	  <div className="bg-alt min-h-screen">
      <StaticPageTitle>
        <Trans message="Account Settings" />
      </StaticPageTitle>
		  <div style={{position: "sticky",top: "0",zIndex: "1"}}>
      <Navbar menuPosition="account-settings-page" size="sm" color="bg-alt"/>
		  </div>
      <div>
        <div className="container mx-auto my-24 px-24">
          <div className={`${activeTab === 'account' || activeTab === 'security' ? 'sb':'nsb'}`} >
          <h1 className="text-3xl">
            <Trans message="Account settings" />
          </h1>
          <div className="mb-20 text-main text-base">
            <Trans message="View and update your account details, profile and more." />
          </div>
        	<TabList tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
          {isLoading || !data ? (
            <ProgressCircle
              className="my-80"
              aria-label="Loading user.."
              isIndeterminate
            />
          ) : (
            <div className="flex items-start gap-24">
              <main className="flex-auto">
						  {activeTab === 'account' && (
						  <div className="acc-details">
					  <BasicInfoPanel user={data.user} />
					<LocalizationPanel user={data.user} />
					<DangerZonePanel />
						  </div>)}
						  {activeTab === 'billing' && (
						  <div><AccountSettingsPanel
					  id={AccountSettingsId.Billing}
					  title={<Trans message="Billing" />}
						>
						  <StorageUsed />
					  <div className="max-w-full" style={{display:"flex",justifyContent:"space-between",alignItems:"flex-end",marginTop:"-2rem"}}>
						  <div className="storage-meter-settings" style={{marginTop: "20px"}}>
						<Trans message="Storage used:" />
						  	<StorageMeter />
							  </div>
							  <Button
							  elementType={Link}
							  to={isSubscribed ? '/billing' : '/pricing'}
							  variant="outline"
							  color="primary"
						 >
							  <Trans message="Plan Settings" />
						 </Button>
					  </div>
					</AccountSettingsPanel></div>)}
						  {activeTab === 'security' && (
						  <div className="security"><ChangePasswordPanel /><SocialLoginPanel user={data.user} />
				  <AccountSettingsPanel
                  id={AccountSettingsId.TwoFactor}
                  title={<Trans message="Two factor authentication" />}
                >
                  <div className="max-w-580">
                    <TwoFactorStepper user={data.user} />
                  </div>
                </AccountSettingsPanel>
				  <SessionsPanel user={data.user} /></div>)}
						  {activeTab === 'notifications' && (
						  <NotificationSettingsPage />)}
						  {activeTab === 'developers' && (<AccessTokenPanel user={data.user} />)}
              </main>
            </div>
          )}
			</div>
        </div>
      </div>
    </div>
  );
}
interface ItemProps {
  children: ReactNode;
  icon: ReactNode;
  isLast?: boolean;
  panel: AccountSettingsId;
}
function Item({children, icon, isLast, panel,setActiveTab}: ItemProps) {
	const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <ListItem
      startIcon={icon}
      className={isLast ? undefined : 'mb-10'}
    >
      {children}
    </ListItem>
  );
}