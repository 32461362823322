import {Link} from 'react-router-dom';
import {CustomMenu} from '../../../menus/custom-menu';
import {useSettings} from '../../../core/settings/use-settings';

export function AuthLayoutFooter() {
  const {branding} = useSettings();
  return (
    <div className="pt-42 w-60 pb-32 flex items-center gap-30 text-sm text-muted" style={{position: "absolute",right:"50%",left:"50%", bottom: "0", transform: "translate(-50%,0)"}}>
      <Link className="hover:text-fg-base transition-colors w-60" to="/">
        © Drime
      </Link>
      <CustomMenu
        menu="auth-page-footer"
        orientation="horizontal"
        itemClassName="hover:text-fg-base transition-colors"
      />
    </div>
  );
}
