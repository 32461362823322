// @ts-nocheck
import React, { useState, ReactElement, useContext, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';
import {ListboxItemProps} from '@common/ui/forms/listbox/item';
import {NavbarAuthMenu} from '@common/ui/navigation/navbar/navbar-auth-menu';
import {SiteConfigContext} from '@common/core/settings/site-config-context';
import {useLogout} from '@common/auth/requests/logout';
import {useCustomMenu} from '@common/menus/use-custom-menu';
import {useIsMobileMediaQuery} from '@common/utils/hooks/is-mobile-media-query';
import {useSettings} from '@common/core/settings/use-settings';
import {useAuth} from '@common/auth/use-auth';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';
import {
  Menu,
  MenuItem,
  MenuTrigger,
} from '@common/ui/navigation/menu/menu-trigger';
import {NotificationsIcon} from '@common/icons/material/Notifications';
import {Trans} from '@common/i18n/trans';
import {PaymentsIcon} from '@common/icons/material/Payments';
import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import {AccountCircleIcon} from '@common/icons/material/AccountCircle';
import {DarkModeIcon} from '@common/icons/material/DarkMode';
import {LightModeIcon} from '@common/icons/material/LightMode';
import {ExitNavIcon} from '@common/icons/material/ExitNav';
import {CableNavIcon} from '@common/icons/material/CableNav';
import {SecurityNavIcon} from '@common/icons/material/SecurityNav';
import {UpNavIcon} from '@common/icons/material/UpNav';
import {SettingsIcon} from '@common/icons/material/Settings';
import {Button} from '@common/ui/buttons/button';


export interface NavbarAuthUserProps {
  items?: ReactElement<ListboxItemProps>[];
}
export function NavbarAuthUserHome({items = []}: NavbarAuthUserProps) {
  const isMobile = useIsMobileMediaQuery();

const {user, isSubscribed} = useAuth();
	const {auth} = useContext(SiteConfigContext);
  const logout = useLogout();
  const {selectedTheme, selectTheme} = useThemeSelector();
  const hasUnreadNotif = !!user.unread_notifications_count;
  const {notifications, themes} = useSettings();
  const navigate = useNavigate();
  if (!selectedTheme || !user) return null;
	const dropdownRef = useRef(null);
	
  return (
	  <div class="relative">
      <a 
          href="https://app.drime.cloud/drive" 
		  className="flex items-center"
		  target="_blank">
      <img
        className="w-36 h-36 object-cover flex-shrink-0 rounded"
        src={user.avatar}
        alt=""
		  style={{borderRadius: "50%"}}
      />
    </a>
	</div>
  );
}
