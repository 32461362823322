import {DriveEntry} from '../drive-entry';
import {usePaginatedEntries, usePaginatedEntriesForRecent} from './use-paginated-entries';

export function useEntries(): DriveEntry[] {
  const query = usePaginatedEntries();
  if (!query.data) return [];
  return query.data.pages.flatMap(p => p.data);
}

export function useEntriesForRecent(): DriveEntry[] {
  const query = usePaginatedEntriesForRecent();
  if (!query.data) return [];
  const entries = query.data.pages.flatMap(p => p.data);
  return entries.slice(0,5);
}