import react from 'react';
import {apiClient, queryClient} from '@common/http/query-client';

export async function getfilesEnd() {
	const files = await apiClient
		.get('drive/file-entriesAll')
		.then(response => response.data);
	const imageExt = ['png','jpg','jpeg','gif','avif','webp','svg','ico'];
	const videosExt = ['mp4','m4v','mpeg','mpg','avi','asf'];
	const documentsExt = ['doc','docx','htm','html','css','js','scss','jsx','tsx','odt','txt','odp','ods','xls','pdf','pptx','csv'];
	const audiosExt = ['mp3','mp2','wav','wave','pcm','wma','ogg','aac'];
	let img = 0;
	let videos = 0;
	let docs = 0;
	let audios = 0;
	let others = 0;
	
	for(let i in files){
		let ext = files[i].extension;
		if (ext != null){
			if(imageExt.includes(ext)){
				img += 1;
			}else if(videosExt.includes(ext)){
				videos += 1;
			}else if(documentsExt.includes(ext)){
				docs += 1;
			}else if(audiosExt.includes(ext)){
				audios += 1;
			}else {
				others += 1;
			}
		}
	}
	return {'img': img, 'vid': videos, 'docs': docs, 'aud': audios, 'oth': others, 'tot':img+videos+docs+audios+others};
}