//@ts-nocheck
import react, {useState,useEffect} from 'react';
import {getfilesEnd} from './getfilesEnd';

export function StorageUsed() {
	const [files, setFiles] = useState({});
	useEffect(() => {
		async function getfiles(){
			await getfilesEnd().then((response) => {
				setFiles(response);
			});
		}
		getfiles();
	});
	const data = [files.img/files.tot*100,files.vid/files.tot*100,files.docs/files.tot*100,files.aud/files.tot*100,files.oth/files.tot*100];
	const labels = [
	  { color: '#2AD95D', text: 'Photos' },
	  { color: '#E0484D', text: 'Videos' },
	  { color: '#567CF9', text: 'Documents' },
	  { color: '#9230FC', text: 'Audios' },
	  { color: '#9e9e9e', text: 'Others' },
	];
  return (
  	<div className="mb-24">
		  Storage:
		   <div className="progress-bar-container w-full">
			  {data.map((percentage, index) => (
				  <div
					className="progress-bar"
					style={{ width: `${percentage}%`, backgroundColor: labels[index].color }}
				  ></div>
			  ))}
		  </div>
		  <div className="flex align-center justify-start">
			   {data.map((percentage, index) => (
				  <div className="legend">
					<div className="legend-circle" style={{ backgroundColor: labels[index].color }}></div>
					<span>{labels[index].text} : {percentage.toFixed(1)}%</span>
				  </div>
				   ))}
		  </div>
	</div>
  );
}