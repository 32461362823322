import {createSvgIcon} from '../../../icons/create-svg-icon';

export const WordFileIcon = createSvgIcon(
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 500">
  <path className="cls-2" d="m400,75v425H75c-41.42,0-75-33.58-75-75V0h325c41.42,0,75,33.58,75,75Z"/>
  <rect className="cls-1" x="75" y="162.5" width="250" height="35"/>
  <rect className="cls-1" x="75" y="233" width="250" height="35"/>
  <rect className="cls-1" x="75" y="302" width="200" height="35"/>
</svg>
);
