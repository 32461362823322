import {createSvgIcon} from '../create-svg-icon';

export const InsertDriveFileIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="35 150 400 500">
  <path fill="#ff4f00" d="m436.9,225.9v425H111.9c-41.42,0-75-33.58-75-75V150.9h325c41.42,0,75,33.58,75,75Z"/>
  <rect fill="#fff" x="180" y="313.4" width="182.01" height="35"/>
  <rect fill="#fff" x="180" y="383.9" width="182.01" height="35"/>
  <rect fill="#fff" x="180" y="452.9" width="182.29" height="35"/>
  <rect fill="#fff" x="111.22" y="313.4" width="35" height="35"/>
  <rect fill="#fff" x="111.22" y="383.9" width="35" height="35"/>
  <rect fill="#fff" x="111.24" y="452.9" width="35" height="35"/>
</svg>
, 'InsertDriveFileOutlined');
