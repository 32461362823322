import {createSvgIcon} from '../../../icons/create-svg-icon';

export const PdfFileIcon = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="60 0 400 500">
  <g>
    <path d="m136.39,485c-33.08,0-60-26.92-60-60V15h310c33.08,0,60,26.92,60,60v410H136.39Z" fill="#fff"/>
    <path d="m386.39,30c24.81,0,45,20.19,45,45v395H136.39c-24.81,0-45-20.19-45-45V30h295m0-30H61.39v425c0,41.42,33.58,75,75,75h325V75c0-41.42-33.58-75-75-75h0Z" fill="#cb0606"/>
  </g>
  <path d="m360.38,275.89c-15.47-16.07-57.72-9.52-67.84-8.33-14.88-14.28-24.99-31.54-28.56-37.49,5.36-16.07,8.92-32.13,9.52-49.39,0-14.88-5.95-30.94-22.61-30.94-5.95,0-11.31,3.57-14.28,8.33-7.14,12.5-4.17,37.49,7.14,63.08-6.54,18.45-12.5,36.3-29.16,67.84-17.26,7.14-53.56,23.8-56.53,41.65-1.19,5.35.59,10.71,4.76,14.88,4.17,3.57,9.52,5.36,14.88,5.36,22.02,0,43.44-30.35,58.32-55.94,12.5-4.16,32.13-10.12,51.77-13.69,23.21,20.23,43.44,23.21,54.15,23.21,14.28,0,19.64-5.95,21.42-11.31,2.97-5.95,1.19-12.49-2.97-17.26h0Zm-14.88,10.12c-.59,4.17-5.95,8.33-15.47,5.95-11.31-2.97-21.42-8.33-30.35-15.47,7.74-1.19,24.99-2.98,37.49-.59,4.76,1.19,9.52,4.17,8.33,10.12h0Zm-99.37-122.58c1.19-1.78,2.98-2.97,4.76-2.97,5.36,0,6.54,6.54,6.54,11.9-.59,12.5-2.98,24.99-7.14,36.89-8.93-23.8-7.14-40.47-4.17-45.82Zm-1.19,115.44c4.76-9.52,11.31-26.18,13.69-33.32,5.36,8.92,14.28,19.64,19.04,24.4,0,.6-18.45,4.17-32.73,8.93Zm-35.11,23.8c-13.69,22.61-27.97,36.89-35.7,36.89-1.19,0-2.38-.59-3.57-1.19-1.79-1.19-2.38-2.98-1.79-5.36,1.79-8.33,17.26-19.64,41.06-30.35h0Z" fill="#cb0606"/>
</svg>
);
