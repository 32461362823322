import {createSvgIcon} from '../create-svg-icon';

export const CreateSheetIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="10 10 400 505">
  <path fill="#00b657" d="m412,87.84v425H87c-41.42,0-75-33.58-75-75V12.84h325c41.42,0,75,33.58,75,75Z"/>
  <rect fill="#fff" x="87" y="175.34" width="250" height="35"/>
  <rect fill="#fff" x="87" y="245.84" width="250" height="35"/>
  <rect fill="#fff" x="87" y="314.84" width="250" height="35"/>
  <rect fill="#fff" x="18" y="245" width="173" height="35" transform="translate(367 158) rotate(90)"/>
  <rect fill="#fff" x="233" y="245" width="173" height="35" transform="translate(582 -57) rotate(90)"/>
  <rect fill="#fff" x="125" y="246" width="173" height="35" transform="translate(475 52) rotate(90)"/>
</svg>
, 'CreateSheetOutlined');
