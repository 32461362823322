// @ts-nocheck
import React, { useState, ReactElement, useContext, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';
import {ListboxItemProps} from '@common/ui/forms/listbox/item';
import {NavbarAuthMenu} from '@common/ui/navigation/navbar/navbar-auth-menu';
import {SiteConfigContext} from '@common/core/settings/site-config-context';
import {useLogout} from '@common/auth/requests/logout';
import {useCustomMenu} from '@common/menus/use-custom-menu';
import {useIsMobileMediaQuery} from '@common/utils/hooks/is-mobile-media-query';
import {useSettings} from '@common/core/settings/use-settings';
import {useAuth} from '@common/auth/use-auth';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';
import {
  Menu,
  MenuItem,
  MenuTrigger,
} from '@common/ui/navigation/menu/menu-trigger';
import {NotificationsIcon} from '@common/icons/material/Notifications';
import {Trans} from '@common/i18n/trans';
import {PaymentsIcon} from '@common/icons/material/Payments';
import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import {AccountCircleIcon} from '@common/icons/material/AccountCircle';
import {DarkModeIcon} from '@common/icons/material/DarkMode';
import {LightModeIcon} from '@common/icons/material/LightMode';
import {ExitNavIcon} from '@common/icons/material/ExitNav';
import {CableNavIcon} from '@common/icons/material/CableNav';
import {SecurityNavIcon} from '@common/icons/material/SecurityNav';
import {UpNavIcon} from '@common/icons/material/UpNav';
import {SettingsIcon} from '@common/icons/material/Settings';
import {Button} from '@common/ui/buttons/button';
import {PopoverAnimation} from '@common/ui/overlays/popover-animation';
import {AnimatePresence,m} from 'framer-motion';

export interface NavbarAuthUserProps {
  items?: ReactElement<ListboxItemProps>[];
}
export function NavbarAuthUser({items = []}: NavbarAuthUserProps) {
  const isMobile = useIsMobileMediaQuery();
	const {user, isSubscribed} = useAuth();
	const {auth} = useContext(SiteConfigContext);
  const logout = useLogout();
  const {selectedTheme, selectTheme} = useThemeSelector();
  const hasUnreadNotif = !!user.unread_notifications_count;
  const {notifications, themes} = useSettings();
  const navigate = useNavigate();
  if (!selectedTheme || !user) return null;
	const dropdownRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
	
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);
  const notifMenuItem = (
    <li><Button
          elementType={Link}
			size="sm"
          startIcon={<NotificationsIcon />}
          to={'/notifications'}
        >
      <Trans message="Notifications" />
      {hasUnreadNotif ? ` (${user.unread_notifications_count})` : undefined}
    </Button></li>
  );

  const billingMenuItem = (
    <li style={{width: "250px"}}>
		  <Button
              elementType={Link}
              to={'/billing'}
              size="sm"
              startIcon={<UpNavIcon />}
			  style={{width: "250px"}}
          >
			  <Trans message="Upgrade" />
          </Button></li>
  );
  return (
	 <>
	  <div class="relative" ref={dropdownRef}>
      <button className="flex items-center" onClick={() => setMenuOpen(!isMenuOpen)}>
      <img
        className="w-32 h-32 object-cover flex-shrink-0 rounded mr-12"
        src={user.avatar}
        alt=""
		  style={{borderRadius: "50%"}}
      />
    </button>
		  <div class={` ${isMenuOpen ? 'menu-open' : ''} z-10`}>
		<AnimatePresence>
		  {isMenuOpen && (
			<m.ul
			role="presentation"
			{...PopoverAnimation}
	 className="z-popover isolate dropdown-menu-navbar z-20 shadow-2xl border">
          <li className="flex pl-8 items-center">
			  <img
				className="w-32 h-32 object-cover flex-shrink-0 rounded mr-12"
				src={user.avatar}
				alt=""
		  		style={{borderRadius: "50%"}}
      			/>
			  <div>
			  <span className="block text-sm mr-2 max-w-180 overflow-x-hidden overflow-ellipsis">
				{user.display_name}
			  </span>
			  <span className="block text-sm mr-2 max-w-180 overflow-x-hidden overflow-ellipsis email-navbar">
				{user.email}
			  </span>
			  </div>
		  </li>
          <li style={{width: "250px"}}>
          <Button
              elementType={Link}
              to={'/account-settings'}
              size="sm"
              startIcon={<SettingsIcon />}
			  className="button-drop-nav"
          >
					  <Trans message="Settings" />
          </Button></li>
		  <li style={{width: "250px"}}><Button
			elementType={Link}
              to={'/account-settings#security'}
              size="sm"
            startIcon={<SecurityNavIcon />}
				  className="button-drop-nav"
          >
					  <Trans message="Security" />
          </Button></li>
		  <li style={{width: "250px"}}><Button
			elementType={Link}
              to={'/account-settings#developers'}
              size="sm"
            startIcon={<CableNavIcon />}
				  className="button-drop-nav"
          >
					  <Trans message="API & Documentation" />
          </Button></li>
		  <li style={{width: "250px"}}><Button
			elementType={Link}
              to={'/account-settings#notifications'}
              size="sm"
            startIcon={<NotificationsIcon />}
				  className="button-drop-nav"
          >
					  <Trans message="Notifications" />
          </Button></li>
		  {billingMenuItem}
		  <li style={{width: "250px"}}>{themes?.user_change && !selectedTheme.is_dark && (
          <Button
            value="light"
            startIcon={<DarkModeIcon />}
            onClick={() => {
              selectTheme('dark');
            }}
			  className="button-drop-nav"
          >
					  <Trans message="Dark mode" />
          </Button>
        )}
        {themes?.user_change && selectedTheme.is_dark && (
          <Button
            value="dark"
            startIcon={<LightModeIcon />}
			  className="button-drop-nav"
            onClick={() => {
              selectTheme('light');
            }}
			  >
					  <Trans message="Light mode" />
          </Button>
        )}</li>
		  <li style={{width: "250px"}}><Button
          value="logout"
          startIcon={<ExitNavIcon />}
				  className="button-drop-nav"
          onClick={() => {
            logout.mutate();
          }}
        >
			  <Trans message="Log Out" />
        </Button></li>
		</m.ul>
      )}
			  </AnimatePresence>
		  </div>
	</div>
		 </>
  );
}
