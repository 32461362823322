import {createSvgIcon} from '../../../icons/create-svg-icon';

export const DefaultFileIcon = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="35 150 400 500">
  <path fill="#bdbdbd" d="m436.61,224.94v425H111.61c-41.42,0-75-33.58-75-75V149.94h325c41.42,0,75,33.58,75,75Z"/>
  <rect fill="#fff" x="111.61" y="312.44" width="250" height="35"/>
  <rect fill="#fff" x="111.61" y="382.94" width="250" height="35"/>
  <rect fill="#fff" x="111.61" y="451.94" width="250.39" height="35"/>
</svg>
);
