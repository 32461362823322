//@ts-nocheck
import {EntryAction} from './entry-action';
import {message} from '@common/i18n/message';
import {driveState, useDriveStore} from '../drive-store';
import {DrivePage, TrashPage} from '../drive-page/drive-page';
import {NewFolderIcon} from '@common/icons/material/NewFolder';
import {useDriveUploadQueue} from '../uploading/use-drive-upload-queue';
import {openUploadWindow} from '@common/uploads/utils/open-upload-window';
import {UploadedFile} from '@common/uploads/uploaded-file';
import {UploadNewFileIcon} from '@common/icons/material/UploadNewFile';
import {UploadNewFolderIcon} from '@common/icons/material/UploadNewFolder';
import {useDeleteEntries} from '../files/queries/use-delete-entries';
import {DeleteForeverIcon} from '@common/icons/material/DeleteForever';
import {CreateDocumentIcon} from '@common/icons/material/CreateDocument';
import {CreatePPIcon} from '@common/icons/material/CreatePP';
import {CreateSheetIcon} from '@common/icons/material/CreateSheet';
import {InsertDriveFileIcon} from '@common/icons/material/InsertDriveFile';
import axios from "axios";
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {invalidateEntryQueries} from '../drive-query-keys';
import {useActiveWorkspaceId} from '@common/workspace/active-workspace-id-context';

export function useDrivePageActions(page: DrivePage): EntryAction[] {
  const newFolder = useNewFolderAction(page);
  const uploadFiles = useUploadFilesAction(page);
  const uploadFolder = useUploadFolderAction(page);
  const emptyTrash = useEmptyTrashAction(page);
  const newDoc = useCreateDocumentAction(page);
  const newSheet = useCreateSheetAction(page);
  const newPP = useCreatePowerPointAction(page);
  const NewNot = useCreateNoteAction(page);
  return [newFolder, uploadFiles, uploadFolder,NewNot, newDoc, newSheet, newPP, emptyTrash].filter(
    action => !!action
  ) as EntryAction[];
}

export function useBackupPageActions(page: DrivePage): EntryAction[] {
  return [].filter(
    action => !!action
  ) as EntryAction[];
}


function useNewFolderAction(page: DrivePage): EntryAction | undefined {
  if (!page.folder || !page.folder.permissions['files.update']) return;
  return {
    label: message('New folder'),
    icon: NewFolderIcon,
    key: 'newFolder',
    execute: () => {
      if (page.folder) {
        driveState().setActiveActionDialog('newFolder', [page.folder]);
      }
    },
  };
}

function useUploadFilesAction(page: DrivePage): EntryAction | undefined {
  const {uploadFiles} = useDriveUploadQueue();
  if (!page.folder || !page.folder.permissions['files.update']) return;
  return {
    label: message('Upload files'),
    icon: UploadNewFileIcon,
    key: 'uploadFiles',
    execute: async () => {
      uploadFiles(await openUploadWindow({multiple: true}));
    },
  };
}

function useUploadFolderAction(page: DrivePage): EntryAction | undefined {
  const {uploadFiles} = useDriveUploadQueue();
  if (!page.folder || !page.folder.permissions['files.update']) return;
  return {
    label: message('Upload folder'),
    icon: UploadNewFolderIcon,
    key: 'uploadFolder',
    execute: async () => {
      uploadFiles(await openUploadWindow({directory: true}));
    },
  };
}
function useCreateDocumentAction(page: DrivePage): EntryAction | undefined {
	let wId = useActiveWorkspaceId();
	if (!page.folder || !page.folder.permissions['files.update']) return;
  return {
    label: message('New document'),
    icon: CreateDocumentIcon,
    key: 'createDoc',
    execute: async () => {
      const res = await axios.post('https://app.drime.cloud/api/v1/createDoc',{'id': page.folder.id,'wId': wId.workspaceId});
	  if(res){
		  invalidateEntryQueries();
	  }
    },
  };
}
function useCreateSheetAction(page: DrivePage): EntryAction | undefined {
	let wId = useActiveWorkspaceId();
	if (!page.folder || !page.folder.permissions['files.update']) return;
  return {
    label: message('New sheet'),
    icon: CreateSheetIcon,
    key: 'createSheet',
    execute: async () => {
      const res = await axios.post('https://app.drime.cloud/api/v1/createSheet',{'id': page.folder.id,'wId': wId.workspaceId});
	  if(res){
		  invalidateEntryQueries();
	  }
    },
  };
}
function useCreatePowerPointAction(page: DrivePage): EntryAction | undefined {
	let wId = useActiveWorkspaceId();
	if (!page.folder || !page.folder.permissions['files.update']) return;
  return {
    label: message('New presentation'),
    icon: CreatePPIcon,
    key: 'createPP',
    execute: async () => {
      const res = await axios.post('https://app.drime.cloud/api/v1/createSlide',{'id': page.folder.id,'wId': wId.workspaceId});
	  if(res){
		  invalidateEntryQueries();
	  }
    },
  };
}
function useCreateNoteAction(page: DrivePage): EntryAction | undefined {
	const navigate = useNavigate();
	if (!page.folder || !page.folder.permissions['files.update']) return;
  return {
    label: message('New note'),
    icon: InsertDriveFileIcon,
    key: 'createNote',
    execute: async () => {
      navigate('/notes');
    },
  };
}

function useEmptyTrashAction(page: DrivePage): EntryAction | undefined {
  const deleteEntries = useDeleteEntries();
  const activePage = useDriveStore(s => s.activePage);
  if (activePage !== TrashPage) return;
  return {
    label: message('Empty trash'),
    icon: DeleteForeverIcon,
    key: 'emptyTrash',
    execute: () => {
      deleteEntries.mutate({entryIds: [], emptyTrash: true});
      driveState().selectEntries([]);
    },
  };
}
