import React from 'react';
import {useStorageSummary} from './storage-summary';
import {CloudIcon} from '@common/icons/material/Cloud';
import {Meter} from '@common/ui/progress/meter';
import {Trans} from '@common/i18n/trans';
import clsx from 'clsx';

export function StorageMeter() {
  const {isLoading, data} = useStorageSummary();

  // prevent translation placeholders from showing if summary is not loaded yet
  const label = (
    <span style={{fontSize: "15px",marginLeft: "10px",marginRight: "10px"}} className={clsx('whitespace-nowrap', isLoading && 'invisible')}>
      <CloudIcon size="md" className="icon-md -mt-2" style={{marginRight: "15px"}}/>
      <Trans
        message=" :used / :available    "
        values={{
          used: data?.usedFormatted,
          available: data?.availableFormatted,
        }}
      />
    </span>
  );
  return (
    <div className="px-24 w-full pt-20 flex items-center justify-start gap-16" style={{display: "grid", placeItems: "center"}}>
      <Meter
        className="flex-auto max-w-144"
        size="xs"
        value={data?.percentage}
        label={label}
        showValueLabel={false}
        labelPosition="top"
      />
    </div>
  );
}
