import {IconButton} from '../../ui/buttons/icon-button';
import {NotificationsIcon} from '../../icons/material/Notifications';
import {Button} from '../../ui/buttons/button';
import {useUserNotifications} from './requests/user-notifications';
import {ProgressCircle} from '../../ui/progress/progress-circle';
import {NotificationList} from '../notification-list';
import {DialogTrigger} from '../../ui/overlays/dialog/dialog-trigger';
import {Dialog} from '../../ui/overlays/dialog/dialog';
import {DialogHeader} from '../../ui/overlays/dialog/dialog-header';
import {DialogBody} from '../../ui/overlays/dialog/dialog-body';
import {Trans} from '../../i18n/trans';
import {useAuth} from '../../auth/use-auth';
import {Badge} from '../../ui/badge/badge';
import {DoneAllIcon} from '../../icons/material/DoneAll';
import {useMarkNotificationsAsRead} from '../requests/use-mark-notifications-as-read';
import {NotificationEmptyStateMessage} from '../empty-state/notification-empty-state-message';
import {HelpNavIcon} from '@common/icons/material/HelpNav';
import {ArticleIcon} from '@common/icons/material/Article';

interface HelpDialogTriggerProps {
  className?: string;
}
export function HelpDialogTrigger({
  className,
}: HelpDialogTriggerProps) {
  const {user} = useAuth();
  const query = useUserNotifications();

  return (
    <DialogTrigger type="popover">
		  <Badge badgeIsVisible={false}>
        <IconButton size="md" className={`stroked ${className}`}>
          <HelpNavIcon />
        </IconButton>
		  </Badge>
      <Dialog>
        <DialogHeader
          showDivider
        >
          <Trans message="Help Center" />
        </DialogHeader>
        <DialogBody padding="p-0">
          <DialogContent />
        </DialogBody>
      </Dialog>
    </DialogTrigger>
  );
}

function DialogContent() {
  return (
    <div>
      <ul className="flex flex-col items-center justify-start">
		  <a className="w-full flex items-center justify-start gap-8 px-8 py-4 hover:bg-hover bg-white dark:bg-dark text-md" href="https://support.drime.cloud/hc/categories/3/4/accounts" target="_blank">
			  <ArticleIcon />
			  <Trans message="Accounts" />
		  </a>
		  <a className="w-full flex items-center justify-start gap-8 px-8 py-4 hover:bg-hover bg-white dark:bg-dark text-md" href="https://support.drime.cloud/hc/categories/3/5/using-drime" target="_blank">
			  <ArticleIcon />
			  <Trans message="Using Drime" />
		  </a>
		  <a className="w-full flex items-center justify-start gap-8 px-8 py-4 hover:bg-hover bg-white dark:bg-dark text-md" href="https://support.drime.cloud/hc/categories/3/6/security" target="_blank">
			  <ArticleIcon />
			  <Trans message="Security" />
		  </a>
		  <a className="w-full flex items-center justify-start gap-8 px-8 py-4 hover:bg-hover bg-white dark:bg-dark text-md" href="https://support.drime.cloud/hc/categories/3/11/plans-and-storage" target="_blank">
			  <ArticleIcon />
			  <Trans message="Plans & Storage" />
		  </a>
		  <a className="w-full flex items-center justify-start gap-8 px-8 py-4 hover:bg-hover bg-white dark:bg-dark text-md" href="https://support.drime.cloud/hc/categories/3/12/installs-and-apps" target="_blank">
			  <ArticleIcon />
			  <Trans message="Installs & Apps" />
		  </a>
	  </ul>
    </div>
  );
}
